import React from "react"
import "./title.scss"

const Title = ({ title, align, withBorder, color, className, ...rest }) => {
  const textColor = `title__text--${color}` || ""
  const $align = align ? `title--${align}` : ""

  return (
    <div className={`title ${$align} ${className}`} {...rest}>
      <h2 className={`title__text ${textColor} `}>{title}</h2>
      {withBorder && <div className={`title__border`} />}
    </div>
  )
}

export default Title
