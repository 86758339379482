import React from "react"
import { useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "./hero.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "laboratorio_bg.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <BackgroundImage
        className="hero"
        objectFit="contain"
        fluid={data?.bg?.childImageSharp?.fluid}
      />
    </>
  )
}

export default Hero
