import React from "react"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import Title from "../components/title"
import Button from "../components/button"

const NOMS_LABORATORY = [
  "NOM-001-SEMERNAT",
  "NOM-002-SEMARNAT",
  "NOM-003-SEMARNAT",
  "NOM-004-SEMARNAT",
  "NOM-127-SSA1",
  "NOM-201-SSA1",
]

const LaboratorioPage = () => {
  return (
    <Layout>
      <Hero />
      <div className="container pt-xs-3">
        <Title color="blue" title="Laboratorio" withBorder={false} />
        <p style={{ textAlign: "center" }}>
          ANÁLISIS PARCIAL Y COMPLETO BAJO PERFIL
        </p>
        <div
          className="grid grid__row-gap mt-xs-4 mb-xs-4 grid--center m-auto"
          style={{ maxWidth: "56.43rem" }}
        >
          {NOMS_LABORATORY &&
            NOMS_LABORATORY.map(nom => (
              <div className="col-12 col-sm-6 col-lg-4" key={nom}>
                <div className="card">
                  <div className="card__body flex flex__center-all bg-gray-secondary">
                    <p className="m-xs-2 text-center">{nom}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="m-auto mt-xs-4 mb-xs-4 text-center">
          <Button className="">Solicitar Servicio</Button>
        </div>
      </div>
    </Layout>
  )
}

export default LaboratorioPage
